import React from "react"
import {Navbar,
  Nav,
  NavItem,
  NavLink} from "reactstrap"
import {Link} from 'gatsby';
import "../styles/navb.css"

const routeLinks =[
  {
    to:'#home/',
    name:'Inicio'
  },
  {
    to:'#guides/',
    name:'Guías'
  },
  {
    to:'#resources/',
    name:'Recursos'
  }
]
export default class Navb extends React.Component {
  constructor(props) {
    super(props);

    this.listener = null;
    this.state = {
      status: "top"
    };
  }

  componentDidMount() {
    this.listener = document.addEventListener("scroll", e => {
      var scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= 800) {
        if (this.state.status !== "try") {
          this.setState({ status: "try" });
        }
      } else {
        if (this.state.status !== "top") {
          this.setState({ status: "top" });
        }
      }
    });
  }

  componentDidUpdate() {
    document.removeEventListener("scroll", this.listener);
  }

render(){
  return (
      <Navbar fixed="top" expand="sm" style={{
          backgroundColor: this.state.status === "top" ? "transparent" : "#a62f8a",
          position: "fixed"
        }}>
        <Nav className="sticky-top navb-margin " navbar>
          {routeLinks.map(({to,name,only_sm},i)=><NavItem key={i}>
                 <NavLink className={`navb-text mx-1 ${only_sm?'d-md-none':''}`} tag={Link} to={to}>
                   <h3 className="">{name}</h3>

                 </NavLink>
               </NavItem>)}
        </Nav>
  </Navbar>
      )
}
      }
